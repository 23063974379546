import { storeToRefs } from 'pinia'

import { useDraftTrackStore } from '~/stores/draftTrack'
import { useDraftTrackTagsStore } from '~/stores/draftTrackTags'
import { useInfluencersDuplicatesStore } from '~/stores/influencersDuplicates'
import { useInfluencersRecommendationsStore } from '~/stores/influencersRecommendations'
import { useTagStore } from '~/stores/tag'
import { useUserBandStore } from '~/stores/userBand'

import type Tag from '~/entities/tag'
import type { Influencer, StatsV3Influencer } from '~/types/influencer'
import type {
  InfluencerExpectationsData,
  InfluencerExpectationsTagName,
  InfluencerExpectationsTags,
} from '~/types/InfluencerExpectations'
import type { FormattedMismatch } from '~/types/influencerRecommendation'
import type { MaybeRefOrGetter } from 'vue'

export type LocationType = 'card' | 'popup' | 'profile'

// FIXME: this needs to be rewritten. Composables shouldn't create nested composables
// and they aren't meant to be looped through.
export function useComputeMismatches() {
  const { $pinia } = useNuxtApp()
  const { t } = useI18n()
  const route = useRoute()
  const influencersRecommendationsStore =
    useInfluencersRecommendationsStore($pinia)
  const { list: DUPLICATE_SUBMISSIONS } = storeToRefs(
    useInfluencersDuplicatesStore($pinia),
  )
  const { GET_MISMATCHES_BY_INFLUENCER_ID } = influencersRecommendationsStore
  const { identity: DRAFT_TRACK_TAGS_IDENTITY } = storeToRefs(
    useDraftTrackTagsStore($pinia),
  )
  const { BAND_DATA } = storeToRefs(useUserBandStore())
  const { release_date: DRAFT_TRACK_RELEASE_DATE } =
    storeToRefs(useDraftTrackStore())
  const { GET_TAGS_FROM_IDS } = useTagStore()

  function isTrackAgeMismatch(
    curatorTrackAge: Tag[],
    draftTrackAge: string,
  ): boolean {
    const isExpectingReleasedTracks =
      curatorTrackAge[0].name === 'released-tracks'
    const now = new Date()
    const releaseDate = new Date(draftTrackAge)
    const isTrackReleased = draftTrackAge && now > releaseDate

    return isTrackReleased !== isExpectingReleasedTracks
  }

  function isTagIdMismatch(tags: Tag[], contextTags: number[]) {
    const haveTagsInCommon = tags.some((tag) => contextTags.includes(tag.id))

    return !haveTagsInCommon
  }

  function isExpectationsMismatch(
    key: InfluencerExpectationsTagName,
    tags: Tag[],
    contextTags: string | number[],
  ): boolean {
    const curatorAcceptsAny = tags[0]?.name === '_all_'

    if (!tags.length || !contextTags.length || curatorAcceptsAny) return false

    const isMismatch =
      key === 'track_age'
        ? isTrackAgeMismatch(tags, contextTags as string)
        : isTagIdMismatch(tags, contextTags as number[])

    return isMismatch
  }

  function getInfluencerExpectationsData(
    influencerRef: MaybeRefOrGetter<Influencer | StatsV3Influencer>,
  ): InfluencerExpectationsData {
    const influencer = toValue(influencerRef)
    const preferencesTags = influencer.tags.exclusivity
    const expectationsTagNames: readonly InfluencerExpectationsTagName[] = [
      'lyrics_lang',
      'country',
      'track_age',
    ]

    const contextTags: InfluencerExpectationsTags = {
      lyrics_lang: DRAFT_TRACK_TAGS_IDENTITY.value?.lyrics_lang ?? [],
      country: BAND_DATA.value?.tags?.identity?.country ?? [],
      track_age: DRAFT_TRACK_RELEASE_DATE.value ?? '',
    }

    const expectationsTagsInfo: InfluencerExpectationsData = {
      lyrics_lang: {
        tags: [],
        isMismatch: false,
      },
      country: {
        tags: [],
        isMismatch: false,
      },
      track_age: {
        tags: [],
        isMismatch: false,
      },
    }
    expectationsTagNames.forEach((key) => {
      const tags = GET_TAGS_FROM_IDS(preferencesTags[key]) || []
      const tagsNames = tags.map((tag: Tag) => tag.name)

      expectationsTagsInfo[key].isMismatch = isExpectationsMismatch(
        key,
        tags,
        contextTags[key],
      )
      expectationsTagsInfo[key].tags = tagsNames
    })

    return expectationsTagsInfo
  }

  function computeMismatches(
    locationRef: MaybeRefOrGetter<LocationType>,
    influencerRef: MaybeRefOrGetter<Influencer | StatsV3Influencer>,
  ) {
    const location = toValue(locationRef)
    const influencer = toValue(influencerRef)

    const influencerExpectationsData = getInfluencerExpectationsData(influencer)

    const influencerMismatches = ref(
      GET_MISMATCHES_BY_INFLUENCER_ID(influencer.id),
    )

    const isDuplicate = computed<boolean>(() =>
      // FIXME: DUPLICATE_SUBMISSIONS.value is an array of numbers and shouldn't need to need to have a ?? [] but it fails without it
      // when loading the catalog page
      (DUPLICATE_SUBMISSIONS.value ?? []).includes(influencer.id),
    )

    const countryMismatch = computed(() => {
      const { entity: influencerName } = influencer
      const { country } = influencerMismatches.value || {}

      if (!country) return ''

      const nationalitiesList = country.tags.map(({ name }) =>
        t(`tags.nationalities.${name}`),
      )
      const formattedNationalitiesList =
        nationalitiesList.length > 1
          ? `${nationalitiesList.slice(0, -1).join(', ')} ${t(
              'common.and',
            )} ${nationalitiesList.slice(-1)}`
          : nationalitiesList[0]
      const args = {
        influencerName,
        nationalitiesList: formattedNationalitiesList,
      }

      switch (location) {
        case 'card':
        case 'popup':
          return t('components.influencer.card.mismatches.countryCard', args)
        case 'profile':
          return t('components.influencer.card.mismatches.countryProfile', args)
        default:
          return t('components.influencer.card.mismatches.countryCard', args)
      }
    })

    const lyricsLangMismatch = computed(() => {
      const { entity: influencerName } = influencer
      const { lyrics_lang: lyricsLangTags } = influencerMismatches.value || {}

      if (!lyricsLangTags) return ''

      const langsList = lyricsLangTags.tags.map(({ name }) =>
        t(`tags.lyrics_lang.${name}`),
      )
      const formattedLangsList =
        langsList.length > 1
          ? `${langsList.slice(0, -1).join(', ')} ${t(
              'common.and',
            )} ${langsList.slice(-1)}`
          : langsList[0]
      const args = {
        influencerName,
        langsList: formattedLangsList,
      }

      switch (location) {
        case 'card':
          if (lyricsLangTags?.tags?.[0]?.name === '_none_') {
            return t(
              'components.influencer.card.mismatches.lyrics_langInstrumental',
            )
          } else {
            return t('components.influencer.card.mismatches.lyrics_lang')
          }

        case 'popup':
          if (lyricsLangTags?.tags?.[0]?.name === '_none_') {
            return t(
              'components.influencer.card.mismatches.lyrics_langInstrumental',
            )
          } else {
            return t(
              'components.influencer.card.mismatches.lyrics_langPopup',
              args,
            )
          }

        case 'profile':
          if (lyricsLangTags?.tags?.[0]?.name === '_none_') {
            return t(
              'components.influencer.card.mismatches.lyrics_langInstrumentalFull',
              args,
            )
          } else {
            return t(
              'components.influencer.card.mismatches.lyrics_langProfile',
              args,
            )
          }

        default:
          return t('components.influencer.card.mismatches.lyrics_lang')
      }
    })

    const trackAgeMismatch = computed(() => {
      const { entity: influencerName } = influencer
      const trackAgeExpectation = influencerExpectationsData.track_age

      if (!trackAgeExpectation.isMismatch) return ''

      const mismatchType = trackAgeExpectation.tags[0]

      if (mismatchType === 'released-tracks') {
        switch (location) {
          case 'card':
          case 'popup':
            return t(
              'components.influencer.card.mismatches.track_age.releasedTracks',
            )
          case 'profile':
            return t(
              'components.influencer.card.mismatches.track_age.releasedTracksProfile',
              { influencerName },
            )
          default:
            return t(
              'components.influencer.card.mismatches.track_age.releasedTracks',
            )
        }
      }
      // in this case mismatchType is obviously set to "unreleased-tracks"
      else {
        switch (location) {
          case 'card':
          case 'popup':
            return t(
              'components.influencer.card.mismatches.track_age.unreleasedTracks',
            )
          case 'profile':
            return t(
              'components.influencer.card.mismatches.track_age.unreleasedTracksProfile',
              { influencerName },
            )
          default:
            return t(
              'components.influencer.card.mismatches.track_age.unreleasedTracks',
            )
        }
      }
    })

    const mismatches = computed<FormattedMismatch[]>(() => {
      const mismatches = []
      const isInDraft = route.path.includes('/draft') || !!route.query?.draftId

      if (isDuplicate.value && isInDraft) {
        mismatches.push({
          mismatchText: t('components.influencer.card.mismatches.duplicate'),
          type: 'duplicate' as const,
          weakMismatch: false,
        })
      }

      if (influencerExpectationsData.country.isMismatch) {
        mismatches.push({
          mismatchText: countryMismatch.value,
          type: 'country' as const,
          weakMismatch: false,
        })
      }

      if (influencerExpectationsData.lyrics_lang.isMismatch) {
        const mismatchedLyricsLang =
          influencerMismatches.value?.lyrics_lang?.tags ?? []

        const acceptedLyricsLangTagIds = mismatchedLyricsLang.map(
          (tag) => tag.id,
        )
        const trackLangTagIds =
          DRAFT_TRACK_TAGS_IDENTITY.value.lyrics_lang ?? []
        const isStrongLyricsLangMisMatch =
          !!trackLangTagIds.length &&
          trackLangTagIds.every(
            (tagId) => !acceptedLyricsLangTagIds?.includes(tagId),
          )

        mismatches.push({
          mismatchText: lyricsLangMismatch.value,
          type: 'lyrics_lang' as const,
          weakMismatch: isInDraft
            ? isStrongLyricsLangMisMatch
              ? false
              : !!influencerMismatches.value?.lyrics_lang?.weak
            : true,
        })
      }
      if (trackAgeMismatch.value) {
        mismatches.push({
          mismatchText: trackAgeMismatch.value,
          type: 'track_age' as const,
          weakMismatch: isInDraft
            ? (influencerMismatches.value?.track_age?.weak ?? false)
            : true,
        })
      }

      return mismatches
    })

    const mostImportantMismatch = computed(() =>
      mismatches.value.length ? mismatches.value[0] : undefined,
    )

    const hasStrongMismatch = computed<boolean>(
      () =>
        !!mostImportantMismatch.value &&
        !!mostImportantMismatch.value.mismatchText &&
        !mostImportantMismatch.value.weakMismatch,
    )

    return { mismatches, mostImportantMismatch, hasStrongMismatch } as const
  }

  return {
    computeMismatches,
    getInfluencerExpectationsData,
  }
}
